import React, { useContext, useState } from 'react';
import { SetContext } from '../context/SetContext';
import { ProfileContext } from '../context/ProfileContext';
import { TimerContext } from '../context/TimerContext';
import { Scrambler } from './comp/Scrambler';
import { Clock } from './comp/Clock';
import { Loading } from '../utils/Loading';
import '../styles/timer.css';

export const Timer = () => {
  const { set, loading: setLoading } = useContext(SetContext);
  const {
    solve,
    scramble,
    generateScramble,
    loading,
    clearScramble,
    timer,
    setKeyDown,
    escapeInspection,
    handlePenalty,
    deleteSolve,
  } = useContext(TimerContext);
  const { settings, updateSettings } = useContext(ProfileContext);

  if (setLoading || loading) {
    return <Loading />;
  }

  const fade = timer.prime === 2 || timer.phase === 2;

  return (
    <>
      <p
        className="back"
        onClick={() => {
          window.location.href = '/';
        }}
      >
        Back Home
      </p>
      <Scrambler
        set={set}
        fade={fade}
        settings={settings}
        updateSettings={updateSettings}
        scramble={scramble}
        generateScramble={generateScramble}
        clearScramble={clearScramble}
      />
      <Clock
        set={set}
        settings={settings}
        timer={timer}
        setKeyDown={setKeyDown}
        escapeInspection={escapeInspection}
        handlePenalty={handlePenalty}
        solve={solve}
        deleteSolve={deleteSolve}
      />
    </>
  );
};
