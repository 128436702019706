import '../../styles/account.css';
import { Registration } from './Registration';
import { useAuth } from '../../context/AuthContext';
import { useProfile } from '../../context/ProfileContext';
import { useState } from 'react';

export const Account = () => {
  const { logout, sendVerificationEmail } = useAuth();
  const { profile, loading } = useProfile();
  const [message, setMessage] = useState(null);

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleSendVerificationEmail = async () => {
    try {
      const response = await sendVerificationEmail();
      setMessage(response);
    } catch (error) {
      setMessage(error.message);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="account">
      {profile ? (
        <div className="information">
          <h2>Profile Information</h2>
          <p className="label">Username</p>
          <p className="value">{profile.username}</p>
          <p className="label">Usercode</p>
          <p className="value">{profile.usercode}</p>
          <p className="label">
            Email {profile.isVerified ? '(Verified)' : '(Unverified)'}
          </p>
          <p className="value">{profile.email}</p>
          {message && <p className="message">{message}</p>}
          <div className="row">
            <button onClick={handleLogout}>Logout</button>
            {!profile.isVerified && (
              <button onClick={handleSendVerificationEmail}>
                Send Verification Email
              </button>
            )}
          </div>
        </div>
      ) : (
        <Registration />
      )}
    </div>
  );
};
