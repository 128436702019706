import { useState } from 'react';
import { Account } from './Account';
import { Settings } from './Settings';

import '../../styles/profile.css';
import { ProfileProvider } from '../../context/ProfileContext';

export const Profile = ({ setPage }) => {
  const [subpage, setSubpage] = useState('account');

  const handlePopupClick = (event) => {
    event.stopPropagation();
  };

  const handleTabClick = (page) => {
    setSubpage(page);
  };

  return (
    <div className="background" onClick={() => setPage(null)}>
      <div className="popup" onClick={handlePopupClick}>
        <div className="header">
          <p
            className={`tab ${subpage === 'account' ? 'active' : ''}`}
            onClick={() => handleTabClick('account')}
          >
            <i className="bi bi-person-fill" /> Account
          </p>
          <p
            className={`tab ${subpage === 'settings' ? 'active' : ''}`}
            onClick={() => handleTabClick('settings')}
          >
            <i className="bi bi-gear-fill" /> Settings
          </p>
          <p className="tab back-x" onClick={() => setPage(null)}>
            <i className="bi bi-x-lg" />
          </p>
        </div>
        <div className="body">
          <ProfileProvider>
            {subpage === 'account' && <Account />}
            {subpage === 'settings' && <Settings />}
          </ProfileProvider>
        </div>
      </div>
    </div>
  );
};
