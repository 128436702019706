import { useState } from 'react';
import { useProfile } from '../../context/ProfileContext';

export const Settings = () => {
  const { settings, updateSettings, loading } = useProfile();
  const [newSettings, setNewSettings] = useState({
    isInspect: settings?.isInspect || false,
    inspection: settings?.inspection || 15,
    priming: settings?.priming || 0.6,
    fontSize: settings?.fontSize || 'default',
  });

  if (loading) {
    return <p>Loading settings...</p>;
  }

  const handleSave = async () => {
    try {
      await updateSettings(newSettings);
      alert('Settings updated successfully!');
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="settings">
      <h2>User Settings</h2>
      <div className="item">
        {newSettings.isInspect ? (
          <i
            className="bi bi-check-square-fill isInspect"
            onClick={() => setNewSettings({ ...newSettings, isInspect: false })}
          />
        ) : (
          <i
            className="bi bi-square isInspect"
            onClick={() => setNewSettings({ ...newSettings, isInspect: true })}
          />
        )}
        <p>Include Inspection</p>
      </div>
      <div className="item">
        <div className="select">
          <i
            className="bi bi-dash"
            onClick={() =>
              setNewSettings({
                ...newSettings,
                inspection: newSettings.inspection - 1,
              })
            }
          />
          <p className="value">{newSettings.inspection}</p>
          <i
            className="bi bi-plus"
            onClick={() =>
              setNewSettings({
                ...newSettings,
                inspection: newSettings.inspection + 1,
              })
            }
          />
        </div>
        <p>Countdown From</p>
      </div>
      <div className="item">
        <div className="select">
          <i
            className="bi bi-dash"
            onClick={() =>
              setNewSettings({
                ...newSettings,
                priming: parseFloat((newSettings.priming - 0.1).toFixed(1)),
              })
            }
          />
          <p className="value">{newSettings.priming}</p>
          <i
            className="bi bi-plus"
            onClick={() =>
              setNewSettings({
                ...newSettings,
                priming: parseFloat((newSettings.priming + 0.1).toFixed(1)),
              })
            }
          />
        </div>
        <p>Timer Priming</p>
      </div>
      <button onClick={handleSave}>Save Settings</button>
    </div>
  );
};
