import { useContext } from 'react';
import { CardContext } from '../../context/CardContext'; // Assuming you have a CardContext for the relevant data
import '../../styles/card.css';

export const Card = ({ username, dateString, setPage }) => {
  const { streak, status } = useContext(CardContext); // Retrieve the data from CardContext

  // Get the current date
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const today = currentDate.getDate();

  // Get the first day of the month and total days in the month
  const firstDay = new Date(year, month, 1).getDay();
  const totalDays = new Date(year, month + 1, 0).getDate();

  // Prepare an array to fill with days for the 7x5 grid
  const days = Array(35).fill(null);

  // Fill the days array with objects containing date information
  for (let i = 0; i < days.length; i++) {
    const dayOfMonth = i - firstDay + 1;
    if (dayOfMonth > 0 && dayOfMonth <= totalDays) {
      days[i] = {
        date: new Date(year, month, dayOfMonth),
        inMonth: true,
        // setsCompleted: monthData[dayOfMonth - 1]?.setsCompleted || 0, // Retrieve sets completed from monthData
      };
    } else {
      days[i] = {
        date: null,
        inMonth: false,
      };
    }
  }

  return (
    <div className="card">
      <div className="text-display">
        <p className="header" onClick={() => setPage('profile')}>
          <i className="bi bi-person-fill" />{' '}
          {username ? username : 'Not Signed In'}
        </p>
        <p className="body">{dateString}</p>
        <p className="footer">
          {username ? (
            <>
              <i className="bi bi-star-fill" /> 7 day streak
            </>
          ) : null}
        </p>
      </div>
      <div>
        {/* <div className="activity">
          {days.map((day, index) => {
            const isWeekend =
              day.inMonth &&
              (day.date.getDay() === 0 || day.date.getDay() === 6);
            const isToday =
              day.inMonth &&
              day.date.getDate() === today &&
              day.date.getMonth() === month &&
              day.date.getFullYear() === year;

            const isPast =
              day.inMonth &&
              day.date.getTime() < currentDate.getTime() &&
              !isToday;

            const isFuture =
              day.inMonth && day.date.getTime() > currentDate.getTime();

            const dayId = day.date
              ? day.date.toISOString().split('T')[0]
              : `out-${index}`;

            // Style based on the number of sets completed
            const setClasses = {
              0: 'zero-sets',
              1: 'one-set',
              2: 'two-sets',
              3: 'three-sets',
            };

            return (
              <div
                key={index}
                id={dayId}
                className={`day ${day.inMonth ? 'in-month' : 'out-of-month'} ${
                  isWeekend ? 'weekend' : 'weekday'
                } ${isToday ? 'today' : ''} ${isPast ? 'past' : ''} ${
                  isFuture ? 'future' : ''
                } ${setClasses[day.setsCompleted] || ''}`}
              ></div>
            );
          })}
        </div> */}
      </div>
    </div>
  );
};
