import Leaves from '../assets/leaves.svg';
import Pot from '../assets/pot.svg';

import '../styles/loading.css';

export const Loading = () => {
  return (
    <div className="loading">
      <div className="icons">
        <img className="leaves" src={Leaves} alt="Leaves" />
        <img className="pot" src={Pot} alt="Pot" />
      </div>
      <p>Loading...</p>
    </div>
  );
};
