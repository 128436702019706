import { createContext, useState, useEffect, useContext } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  Timestamp,
} from 'firebase/firestore';
import { app } from '../firebase/firebase';

export const ProfileContext = createContext();

export const useProfile = () => useContext(ProfileContext);

export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [settings, setSettings] = useState({
    fontSize: 2,
    inspection: 15,
    priming: 0.6,
    isInspect: true,
  });

  const [loading, setLoading] = useState(true);

  const auth = getAuth(app);
  const db = getFirestore(app);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            const profileData = {
              username: userData.username,
              usercode: userData.usercode,
              email: user.email,
              dateJoined:
                userData.dateJoined?.toDate().toLocaleDateString() || 'N/A',
              isVerified: user.emailVerified,
            };

            setProfile(profileData);

            // Set settings state from user data
            setSettings(userData.settings || {});

            // Update Firestore if the email is verified but not reflected in the database
            if (user.emailVerified && !userData.isVerified) {
              await updateDoc(userDocRef, {
                isVerified: true,
                lastEdited: Timestamp.now(),
              });
            }
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        setProfile(null);
        setSettings(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth, db]);

  // Function to update settings
  const updateSettings = async (newSettings) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No authenticated user found');
      }

      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, {
        settings: { ...settings, ...newSettings },
        lastEdited: Timestamp.now(),
      });

      // Update the local state
      setSettings((prevSettings) => ({
        ...prevSettings,
        ...newSettings,
      }));
    } catch (error) {
      console.error('Error updating user settings:', error);
      throw new Error('Failed to update settings. Please try again.');
    }
  };

  return (
    <ProfileContext.Provider
      value={{ profile, settings, loading, updateSettings }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
