import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';

export const Registration = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // New state for loading
  const { createAccount, login } = useAuth();

  const validatePassword = (password) => {
    const errors = [];

    if (password.length < 8) {
      errors.push('Password must be at least 8 characters long.');
    }
    if (!/[A-Z]/.test(password)) {
      errors.push('Password must contain at least one uppercase letter.');
    }
    if (!/[a-z]/.test(password)) {
      errors.push('Password must contain at least one lowercase letter.');
    }
    if (!/[0-9]/.test(password)) {
      errors.push('Password must contain at least one number.');
    }
    if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password)) {
      errors.push('Password must contain at least one special character.');
    }

    return errors;
  };

  const handleRegister = async () => {
    if (username.length > 25) {
      setError('Username cannot exceed 25 characters.');
      return;
    }

    if (!/^[a-zA-Z0-9 ]+$/.test(username)) {
      setError('Username can only contain letters, numbers, and spaces.');
      return;
    }

    const passwordErrors = validatePassword(password);
    if (passwordErrors.length > 0) {
      setError(passwordErrors.join(' '));
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      setLoading(true); // Set loading to true when registration starts
      await createAccount(username, email, password);
      // Clear fields after successful registration
      setUsername('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setError(null);
    } catch (err) {
      if (err.code === 'auth/email-already-in-use') {
        setError(
          'This email is already in use. Please try logging in or use a different email.'
        );
      } else {
        setError(err.message);
      }
    } finally {
      setLoading(false); // Set loading to false after registration finishes
    }
  };

  const handleLogin = async () => {
    try {
      setLoading(true); // Set loading to true when login starts
      await login(email, password);
      setEmail('');
      setPassword('');
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false); // Set loading to false after login finishes
    }
  };

  return (
    <div className="registration">
      {isLogin ? (
        <div className="form login">
          <h2>Login</h2>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            onClick={handleLogin}
            className={loading ? 'loading' : ''}
            disabled={loading}
          >
            {loading ? 'Logging in...' : 'Login'}
          </button>
          <p className="form-switch" onClick={() => setIsLogin(false)}>
            Don't have an account? <strong>Create Account</strong>
          </p>
        </div>
      ) : (
        <div className="form signup">
          <h2>Register</h2>
          <input
            type="text"
            placeholder="Username"
            value={username}
            maxLength={25}
            onChange={(e) => setUsername(e.target.value)}
          />
          <ul>
            <li>25 character max</li>
            <li>Only use letters, numbers, and spaces</li>
            <li>Your usercode will include username</li>
            <li>Usernames can NOT be changed</li>
          </ul>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <ul>
            <li>A verification email will be sent</li>
          </ul>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <ul>
            <li className={password.length >= 8 ? 'pass' : 'fail'}>
              8 character minimum
            </li>
            <li className={/[A-Z]/.test(password) ? 'pass' : 'fail'}>
              1 uppercase letter
            </li>
            <li className={/[a-z]/.test(password) ? 'pass' : 'fail'}>
              1 lowercase letter
            </li>
            <li
              className={
                /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password)
                  ? 'pass'
                  : 'fail'
              }
            >
              1 special character
            </li>
          </ul>
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <ul>
            <li className={password === confirmPassword ? 'pass' : 'fail'}>
              Passwords must match
            </li>
          </ul>
          {error && <p className="error">{error}</p>}
          <button
            onClick={handleRegister}
            className={loading ? 'loading' : ''}
            disabled={loading}
          >
            {loading ? 'Registering...' : 'Register'}
          </button>
          <p className="form-switch" onClick={() => setIsLogin(true)}>
            Already have an account? <strong>Login</strong>
          </p>
        </div>
      )}
    </div>
  );
};
