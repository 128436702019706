export const copyToClipboard = ({ value }) => {
  if (value) {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        alert('Copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy', err);
      });
  }
};

export const formatTime = (ms) => {
  // Convert milliseconds to total seconds
  let totalSeconds = Math.floor(ms / 10) / 100;
  let centiseconds = Math.floor((ms % 1000) / 10);

  // Extract hours, minutes, and seconds
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  // Format hours if present
  const hoursString = hours > 0 ? `${hours}:` : '';

  // Format minutes if present, ensuring two digits if hours are present
  const minutesString =
    minutes > 0 || hours > 0
      ? `${hours > 0 ? String(minutes).padStart(2, '0') : minutes}:`
      : '';

  // Format seconds to always show two digits
  const secondsString = `${
    hours > 0 || minutes > 0
      ? String(Math.floor(seconds)).padStart(2, '0')
      : Math.floor(seconds)
  }`;

  // Format centiseconds to always show two digits
  const centisecondsString = `${centiseconds.toFixed(0).padStart(2, '0')}`;

  // Construct and return the final formatted time string
  return `${hoursString}${minutesString}${secondsString}.${centisecondsString}`;
};

export const calculateDisplay = (solve, phase) => {
  if (phase === 2) return 'Solve';
  if (solve.isPlus && solve.isDNF) return 'DNF';
  if (solve.isPlus && !solve.isDNF) return formatTime(solve.result + 2000);
  if (!solve.isPlus && solve.isDNF) return 'DNF';
  return formatTime(solve.result);
};

export const checkIsComplete = (type, solveCount) => {
  switch (type) {
    case 'sprint':
      return solveCount >= 5;
    case 'pace':
      return solveCount >= 12;
    case 'marathon':
      return solveCount >= 100;
    default:
      return false;
  }
};
