import { useContext, createContext, useState, useEffect, useRef } from 'react';
import { randomScrambleForEvent } from 'https://cdn.cubing.net/v0/js/cubing/scramble';
import { formatTime, calculateDisplay } from '../utils/Utils';
import { SetContext } from './SetContext';
import { ProfileContext } from './ProfileContext';

export const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
  const { settings, loading } = useContext(ProfileContext);
  const { set, handleUploadSolve, handleModifySolve } = useContext(SetContext);

  const PHASES = { IDLE: 0, INSPECTING: 1, SOLVING: 2, SOLVED: 3, WAITING: 4 };
  const PRIMES = { IDLE: 0, PRIMING: 1, PRIMED: 2, WAITING: 3 };

  const [scramble, setScramble] = useState(null);

  const [timer, setTimer] = useState({
    keydown: false,
    phase: 0,
    prime: 0,
    inspection: 0,
    display: 'Ready',
  });

  const [logs, setLogs] = useState({
    lastPress: null,
    lastRelease: null,
    lastEscape: null,
    lastInspection: null,
    lastStart: null,
  });

  const [solve, setSolve] = useState({
    id: null,
    scramble: null,
    inspection: null,
    result: null,
    isPlus: false,
    isDNF: false,
    display: null,
    uploaded: false,
  });

  useEffect(() => {
    getScramble();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSolver = (fields) => {
    setSolve((prevSolve) => ({ ...prevSolve, ...fields }));
  };

  useEffect(() => {
    logsRef.current = logs;
  }, [logs]);

  const logsRef = useRef(logs);
  const countdownRef = useRef(null);
  const inspectionRef = useRef(null);

  const updateTimer = (fields) => {
    setTimer((prevTimer) => ({ ...prevTimer, ...fields }));
  };

  const generateScramble = async () => {
    try {
      const scrambleObject = await randomScrambleForEvent('333');
      setScramble(scrambleObject.toString());
    } catch (error) {
      console.error('Error generating scramble:', error);
    }
  };

  const getScramble = async () => {
    if (set.type) {
      const scramble = set.scrambles[set.solve];
      setScramble(scramble);
    } else {
      generateScramble();
    }
  };

  const clearScramble = () => setScramble(null);

  const setKeyDown = (isKeyDown) => {
    updateTimer({ keydown: isKeyDown });
  };

  const logEvent = (field, value) => {
    setLogs((prevLogs) => ({ ...prevLogs, [field]: value }));
  };

  const escapeInspection = () => {
    clearInterval(countdownRef.current);
    clearTimeout(inspectionRef.current);
    updateTimer({ phase: PHASES.IDLE, display: 'Ready' });
  };

  const endSolve = () => {
    getScramble();
    updateTimer({ phase: PHASES.SOLVED });
    const solveTime = Date.now() - logsRef.current.lastStart;
    updateTimer({ display: formatTime(solveTime) });
    updateSolver({
      result: solveTime,
      display: formatTime(solveTime),
      uploaded: false,
    });
    if (set.type) {
      updateSolver({ uploaded: true });
      handleUploadSolve({
        ...solve,
        result: solveTime,
        display: formatTime(solveTime),
        uploaded: true,
      });
    }
  };

  const primeTimer = () => {
    updateTimer({ prime: PRIMES.PRIMING });
    setTimeout(() => {
      const unbroken =
        Date.now() - logsRef.current.lastRelease >= settings.priming * 1000;
      if (unbroken) {
        updateTimer({ prime: PRIMES.PRIMED });
      }
    }, settings.priming * 1000);
  };

  const startInspection = () => {
    const inspectionDuration = settings.inspection * 1000 + 2000;
    updateTimer({ phase: PHASES.INSPECTING });
    logEvent('lastInspection', Date.now());

    let countdown = settings.inspection - 1;
    updateTimer({ display: `${countdown + 1}` });
    countdownRef.current = setInterval(() => {
      updateTimer({ display: `${countdown}` });
      countdown -= 1;
      if (countdown < 0) {
        clearInterval(countdownRef.current);
        updateTimer({ display: '+2' });
      }
    }, 1000);

    inspectionRef.current = setTimeout(() => {
      clearInterval(countdownRef.current);

      updateTimer({
        phase: PHASES.SOLVED,
        prime: PRIMES.IDLE,
        display: 'DNF',
      });
    }, inspectionDuration);
  };

  const startSolve = () => {
    updateTimer({
      display: 'Solve',
      phase: PHASES.SOLVING,
      prime: PRIMES.IDLE,
    });
    updateSolver({
      id: Date(),
      scramble: scramble,
      isPlus: false,
      isDNF: false,
      display: null,
      result: null,
    });
    logEvent('lastStart', Date.now());

    if (settings.isInspect) {
      updateSolver({ inspection: Date.now() - logsRef.current.lastInspection });
      clearInterval(countdownRef.current);
      clearTimeout(inspectionRef.current);
      inspectionRef.current = null;
    }
  };

  useEffect(() => {
    if (
      timer.prime === PRIMES.WAITING ||
      timer.phase === PHASES.WAITING ||
      set?.isComplete
    ) {
      return;
    }
    if (timer.keydown) {
      logEvent('lastPress', Date.now());

      if (timer.phase === PHASES.SOLVING) {
        endSolve();
      } else if (!settings.isInspect || timer.phase === PHASES.INSPECTING) {
        primeTimer();
      } else if (settings.isInspect && timer.phase === PHASES.IDLE) {
        startInspection();
      }
    } else if (!timer.keydown) {
      logEvent('lastRelease', Date.now());

      if (timer.prime === PRIMES.PRIMING) {
        updateTimer({ prime: PRIMES.IDLE });
      } else if (timer.prime === PRIMES.PRIMED) {
        startSolve();
      } else if (timer.phase === PHASES.SOLVED) {
        updateTimer({ phase: PHASES.IDLE });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer.keydown]);

  const handlePenalty = (penalty) => {
    if (solve.id === null) return;
    const newSolve = { ...solve };
    if (penalty === 'DNF') {
      newSolve.isDNF = !newSolve.isDNF;
    } else if (penalty === 'Plus') {
      newSolve.isPlus = !newSolve.isPlus;
    }
    newSolve.display = calculateDisplay(newSolve, timer.phase);
    updateSolver({ ...newSolve });
    updateTimer({ display: newSolve.display });
    if (set.type) {
      handleModifySolve(newSolve);
    }
  };

  const deleteSolve = () => {
    if (solve.id) {
      updateSolver({
        id: null,
        scramble: null,
        inspection: null,
        isDNF: false,
        isPlus: false,
        result: null,
        display: null,
      });
      updateTimer({ display: 'Ready' });
    }
  };

  return (
    <TimerContext.Provider
      value={{
        solve,
        scramble,
        loading,
        generateScramble,
        clearScramble,
        timer,
        setKeyDown,
        escapeInspection,
        handlePenalty,
        deleteSolve,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};
