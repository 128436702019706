import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { AuthProvider } from './context/AuthContext';
import { CardProvider } from './context/CardContext';
import { Menu } from './menu/Menu';
import { Setup } from './timer/Setup';
import { VerifyEmail } from './menu/comp/VerifyEmail';

import { Keybinds } from './utils/Constants';

import './index.css';
import './styles/app.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <CardProvider>
      <Router>
        <div className="app">
          <Routes>
            <Route path="/" element={<Menu />} />
            <Route path="/timer" element={<Setup />} />
            <Route path="/timer/sprint" element={<Setup />} />
            <Route path="/timer/pace" element={<Setup />} />
            <Route path="/timer/marathon" element={<Setup />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
          </Routes>
          <Keybinds />
        </div>
      </Router>
    </CardProvider>
  </AuthProvider>
);
