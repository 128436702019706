import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { app } from '../firebase/firebase';
import { uploadSolve, modifySolve } from '../firebase/async/Solves';

export const SetContext = createContext();

export const useSet = () => useContext(SetContext);

export const SetProvider = ({ children }) => {
  const [set, setSet] = useState({
    date: new Date()
      .toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(/\//g, '-'),
    type: null,
    isComplete: false,
    solve: 0,
    scrambles: [],
  });

  const [loading, setLoading] = useState(false); // Start as false, only load if needed
  const location = useLocation();
  const auth = getAuth(app);
  const db = getFirestore(app);

  useEffect(() => {
    // Determine the type based on the URL path
    let type = setType();

    // Only fetch data if a specific type is detected
    if (type) {
      setLoading(true);

      const fetchScramblesAndSolves = async () => {
        const scramblesDocRef = doc(db, 'scrambles', set.date);
        try {
          // Fetch scrambles for the date and type
          const scramblesDoc = await getDoc(scramblesDocRef);
          if (scramblesDoc.exists()) {
            const scramblesData = scramblesDoc.data();
            setSet((prevSet) => ({
              ...prevSet,
              scrambles: scramblesData[type] || [],
            }));
          } else {
            console.error('No scrambles found for this date.');
          }

          // Fetch the user's results for the specific date and type
          const user = auth.currentUser;
          if (!user) {
            setLoading(false);
            return;
          }

          const userResultsDocRef = doc(
            db,
            'users',
            user.uid,
            'results',
            set.date
          );
          const userResultsDoc = await getDoc(userResultsDocRef);

          if (userResultsDoc.exists()) {
            const userResultsData = userResultsDoc.data();
            const setResults = userResultsData[type];

            // If set type results exist, determine the lowest non-existent solve index
            if (setResults) {
              const solveIndex = Object.keys(setResults).length - 1;
              setSet((prevSet) => ({
                ...prevSet,
                solve: solveIndex,
                isComplete: setResults.isComplete,
              }));
            } else {
              // If the set type does not exist, set solve to 0
              setSet((prevSet) => ({
                ...prevSet,
                solve: 0,
                isComplete: false,
              }));
            }
          }
        } catch (error) {
          console.error('Error fetching user results or scrambles:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchScramblesAndSolves();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, set.date, db, auth]);

  const setType = () => {
    const path = location.pathname;
    let type = null;

    if (path.includes('/timer/sprint')) {
      type = 'sprint';
    } else if (path.includes('/timer/pace')) {
      type = 'pace';
    } else if (path.includes('/timer/marathon')) {
      type = 'marathon';
    }

    // Update set with the detected type or null if none is detected
    setSet((prevSet) => ({
      ...prevSet,
      type,
    }));

    return type;
  };

  const handleUploadSolve = (solve) => {
    uploadSolve(db, auth, set, solve);
    setSet((prevSet) => ({
      ...prevSet,
      solve: prevSet.solve + 1,
      isComplete: prevSet.solve + 1 === prevSet.scrambles.length,
    }));
  };

  const handleModifySolve = (solve) => {
    modifySolve(db, auth, set, solve);
  };

  return (
    <SetContext.Provider
      value={{
        set,
        setSet,
        loading,
        uploadSolve,
        handleUploadSolve,
        handleModifySolve,
        modifySolve,
      }}
    >
      {children}
    </SetContext.Provider>
  );
};
