import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from './comp/Card';
import { CardContext } from '../context/CardContext';
import { Profile } from './comp/Profile';
import '../styles/menu.css';
import { Loading } from '../utils/Loading';

export const Menu = () => {
  const { username, dateString, loading } = useContext(CardContext);
  const [page, setPage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const navItems = [
    {
      path: '/timer/sprint',
      title: 'Sprint',
      footer: '5 Solves',
    },
    {
      path: '/timer/pace',
      title: 'Pace',
      footer: '12 Solves',
    },
    {
      path: '/timer/marathon',
      title: 'Marathon',
      footer: '100 Solves',
    },
    {
      path: '/timer',
      title: 'Timer',
      footer: 'Extra Practice',
    },
  ];

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? navItems.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === navItems.length - 1 ? 0 : prevIndex + 1
    );
  };

  if (loading) {
    return <Loading />;
  }

  const displayedItem =
    username === null ? navItems[3] : navItems[currentIndex];

  return (
    <>
      {page === 'profile' && <Profile setPage={setPage} />}

      <div className="main">
        <Card username={username} dateString={dateString} setPage={setPage} />
        <div className="nav">
          {username !== null && (
            <i className="bi bi-arrow-left" onClick={handlePrevious} />
          )}

          <div onClick={() => navigate(displayedItem.path)}>
            <p className="title">{displayedItem.title}</p>
            <p className="footer">{displayedItem.footer}</p>
          </div>

          {username !== null && (
            <i className="bi bi-arrow-right" onClick={handleNext} />
          )}
        </div>
      </div>
    </>
  );
};
