import React from 'react';
import { copyToClipboard } from '../../utils/Utils';

export const Scrambler = ({
  set,
  fade,
  settings,
  scramble,
  generateScramble,
  clearScramble,
  updateSettings,
}) => {
  const increaseFontSize = () => {
    if (settings?.fontSize < 5) {
      updateSettings({ fontSize: settings.fontSize + 0.5 });
    }
  };

  const decreaseFontSize = () => {
    if (settings?.fontSize > 1) {
      updateSettings({ fontSize: settings.fontSize - 0.5 });
    }
  };

  return (
    <div className={`scramble ${fade ? 'fade' : ''}`}>
      <div className="buttons">
        <i className="bi bi-dash" onClick={decreaseFontSize} />
        <i className="bi bi-plus" onClick={increaseFontSize} />
        <div className="gap" />
        {set.type ? (
          <p className="set-scramble">
            {set.solve} of {set.scrambles.length}
          </p>
        ) : (
          <i className="bi bi-arrow-repeat" onClick={generateScramble} />
        )}

        <div className="gap" />
        <i
          className="bi bi-clipboard"
          onClick={() => copyToClipboard({ value: scramble })}
        ></i>
        <i className="bi bi-trash" onClick={clearScramble}></i>
      </div>
      <p style={{ fontSize: `${settings?.fontSize || 2}rem` }}>
        {set.isComplete
          ? 'Finished Set'
          : set.type
          ? set.scrambles[set.solve]
          : scramble
          ? scramble
          : 'No Scramble'}
      </p>
    </div>
  );
};
