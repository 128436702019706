import { createContext, useContext } from 'react';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
} from 'firebase/auth';
import { getFirestore, doc, setDoc, Timestamp } from 'firebase/firestore';
import { app } from '../firebase/firebase';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const auth = getAuth(app);
  const db = getFirestore(app);

  const createAccount = async (username, email, password) => {
    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const usercode = generateUserCode(username);
    const userDocRef = doc(db, 'users', user.uid);

    const currentTimestamp = Timestamp.now();

    await setDoc(userDocRef, {
      username,
      usercode,
      email,
      dateJoined: currentTimestamp,
      lastEdited: currentTimestamp,
      isVerified: false,
      settings: {
        isInspect: true,
        inspection: 15,
        priming: 0.6,
        fontSize: 2,
      },
      results: {},
    });

    // Send a verification email
    await sendEmailVerification(user);
  };

  const sendVerificationEmail = async () => {
    try {
      const user = auth.currentUser;
      if (user && !user.emailVerified) {
        await sendEmailVerification(user);
        return 'Verification email sent. Please check your inbox.';
      }
      return 'Email is already verified.';
    } catch (error) {
      console.error('Error sending verification email:', error);
      throw new Error('Failed to send verification email. Please try again.');
    }
  };

  const login = async (email, password) => {
    await signInWithEmailAndPassword(auth, email, password);
  };

  const logout = async () => {
    await signOut(auth);
  };

  const generateUserCode = (username) => {
    const code = Math.floor(1000 + Math.random() * 9000);
    return `${username.replace(/\s+/g, '')}#${code}`;
  };

  return (
    <AuthContext.Provider
      value={{
        createAccount,
        login,
        logout,
        sendVerificationEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
