// utils/uploadSolve.js
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

/**
 * Uploads a solve to Firebase
 *
 * @param {Object} db - Firestore database instance
 * @param {Object} auth - Firebase auth instance
 * @param {Object} set - Set object containing date, type, solve index, etc.
 * @param {Object} solve - Solve object with solve data to upload
 *
 * @returns {Promise<void>}
 */

export const uploadSolve = async (db, auth, set, solve) => {
  const user = auth.currentUser;
  if (!user || !set.type) return;

  const userResultsDocRef = doc(db, 'users', user.uid, 'results', set.date);
  if (!userResultsDocRef) {
    console.error('User results document reference is null.');
    return;
  }

  try {
    const userResultsDoc = await getDoc(userResultsDocRef);
    let userResultsData = userResultsDoc.exists() ? userResultsDoc.data() : {};

    // Initialize the set type object if it doesn't exist
    if (!userResultsData[set.type]) {
      userResultsData[set.type] = { isComplete: false };
    } else if (set.solve === set.scrambles.length - 1) {
      userResultsData[set.type].isComplete = true;
    }

    userResultsData[set.type][set.solve] = solve;

    await setDoc(userResultsDocRef, userResultsData);

    console.log('Solve uploaded successfully');
  } catch (error) {
    console.error('Error uploading solve:', error);
  }
};

export const modifySolve = async (db, auth, set, solve) => {
  console.log('modding', solve);
  const user = auth.currentUser;
  if (!user || !set.type) return;

  const userResultsDocRef = doc(db, 'users', user.uid, 'results', set.date);
  if (!userResultsDocRef) {
    console.error('User results document reference is null.');
    return;
  }

  try {
    const userResultsDoc = await getDoc(userResultsDocRef);
    let userResultsData = userResultsDoc.exists() ? userResultsDoc.data() : {};

    // Initialize the set type object if it doesn't exist
    if (!userResultsData[set.type]) {
      userResultsData[set.type] = {};
    }

    // Update the specific solve at solveIndex with the new values
    userResultsData[set.type][set.solve - 1] = {
      ...userResultsData[set.type][set.solve - 1],
      ...solve,
    };

    await updateDoc(userResultsDocRef, userResultsData);

    console.log('Solve modified successfully');
  } catch (error) {
    console.error('Error modifying solve:', error);
  }
};
