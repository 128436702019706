import React, { useEffect } from 'react';

export const Clock = ({
  set,
  solve,
  settings,
  timer,
  setKeyDown,
  escapeInspection,
  handlePenalty,
  deleteSolve,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === ' ') {
      setKeyDown(true);
    } else if (event.key === 'Escape' || event.key === 'Backspace') {
      escapeInspection();
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === ' ') {
      setKeyDown(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="timer">
      <p className="display">{timer.display}</p>

      <div
        className={`buttons ${
          timer.prime === 2 || timer.phase === 2 ? 'fade' : ''
        }`}
      >
        {solve.isPlus ? (
          <i
            className="bi bi-flag-fill"
            onClick={() => handlePenalty('Plus')}
          />
        ) : (
          <i className="bi bi-flag" onClick={() => handlePenalty('Plus')} />
        )}
        {solve.isDNF ? (
          <i
            className="bi bi-slash-circle-fill"
            onClick={() => handlePenalty('DNF')}
          />
        ) : (
          <i
            className="bi bi-slash-circle"
            onClick={() => handlePenalty('DNF')}
          />
        )}
        <i className="bi bi-bookmark" />
        <div className="gap" />
        <div className={`state ${timer.keydown ? 'down' : ''}`}>
          {!settings.isInspect ? null : timer.phase === 0 ||
            timer.phase === 2 ||
            timer.phase === 3 ||
            timer.phase === 4 ? (
            <i className="bi bi-circle" />
          ) : (
            <i className="bi bi-circle-fill" />
          )}

          {timer.prime === 1 || timer.prime === 2 ? (
            <i className="bi bi-circle-fill" />
          ) : (
            <i className="bi bi-circle" />
          )}
          {timer.prime === 2 ? (
            <i className="bi bi-circle-fill" />
          ) : (
            <i className="bi bi-circle" />
          )}
        </div>
        <div className="gap" />
        <i className="bi bi-eye" />
        <i className="bi bi-clipboard" />
        <i className="bi bi-trash" onClick={() => deleteSolve()} />
      </div>
    </div>
  );
};
