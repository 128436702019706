import '../styles/keybinds.css';

export const Keybinds = () => {
  return (
    <div className="keybinds">
      <Keybind button="S" action="Start Sprint" />
      <Keybind button="P" action="Start Pace" />
      <Keybind button="M" action="Start Marathon" />
    </div>
  );
};

const Keybind = ({ button, action }) => {
  return (
    <div className="keybind">
      <p className="key">{button}</p>
      <p className="action">{action}</p>
    </div>
  );
};
