import { TimerProvider } from '../context/TimerContext';
import { SetProvider } from '../context/SetContext';
import { ProfileProvider } from '../context/ProfileContext';

import { Timer } from './Timer';

export const Setup = () => {
  return (
    <ProfileProvider>
      <SetProvider>
        <TimerProvider>
          <Timer />
        </TimerProvider>
      </SetProvider>
    </ProfileProvider>
  );
};
