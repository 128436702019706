import { useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useLocation } from 'react-router-dom';

export const VerifyEmail = () => {
  const { checkEmailVerification } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const oobCode = params.get('oobCode');
    const mode = params.get('mode');

    // If the mode is 'verifyEmail' and there's an oobCode, call the checkEmailVerification function
    if (mode === 'verifyEmail' && oobCode) {
      checkEmailVerification(oobCode);
    }
  }, [location, checkEmailVerification]);

  return (
    <div>
      <h2>Email Verification</h2>
      <p>Please wait while we verify your email...</p>
    </div>
  );
};
