import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyC1ovAaa9XrMuAPPtv3VErWR1P4JiLcI5E',
  authDomain: 'cuble-90466.firebaseapp.com',
  projectId: 'cuble-90466',
  storageBucket: 'cuble-90466.appspot.com',
  messagingSenderId: '824675248151',
  appId: '1:824675248151:web:4137719359b4fc85631452',
  measurementId: 'G-5T6YC5P6PN',
};

export const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
