import { createContext, useContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { app } from '../firebase/firebase';

export const CardContext = createContext();

export const useCard = () => useContext(CardContext);

export const CardProvider = ({ children }) => {
  const [username, setUsername] = useState(null);
  const [dateString, setDateString] = useState('');
  const [dayStreak, setDayStreak] = useState(0);
  const [status, setStatus] = useState({
    sprint: 'Not Started',
    pace: 'Not Started',
    marathon: 'Not Started',
  });
  const [monthData, setMonthData] = useState([]);
  const [loading, setLoading] = useState(true);

  const auth = getAuth(app);
  const db = getFirestore(app);

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true); // Start loading when the auth state changes

      if (user) {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUsername(userData.username);
          } else {
            console.error('User document does not exist.');
          }

          const currentDate = new Date();
          const day = currentDate.getDate();
          const month = currentDate.toLocaleString('default', {
            month: 'long',
          });
          const daySuffix = ['th', 'st', 'nd', 'rd'][
            day % 10 > 3 ? 0 : day % 10
          ];
          setDateString(`${month} ${day}${daySuffix}`);

          // Fetch today's status and month data in parallel
          const [todayStatus, monthDataFetched] = await Promise.all([
            fetchTodayStatus(user.uid),
            fetchMonthData(user.uid, currentDate),
          ]);

          setStatus(todayStatus);

          setMonthData(monthDataFetched);

          // Optionally fetch and set the day streak
          const streak = await fetchStreak(user.uid);

          setDayStreak(streak);
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          setLoading(false); // Stop loading after data fetch completes
        }
      } else {
        // Reset state if no user is logged in
        setUsername(null);
        setStatus({
          sprint: 'Not Started',
          pace: 'Not Started',
          marathon: 'Not Started',
        });
        setMonthData([]);
        setLoading(false); // Stop loading when no user is present
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, db]);

  const fetchTodayStatus = async (userId) => {
    try {
      const today = new Date()
        .toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
        .replace(/\//g, '-');

      const userResultsDocRef = doc(db, 'users', userId, 'results', today);
      const userResultsDoc = await getDoc(userResultsDocRef);
      if (userResultsDoc.exists()) {
        const data = userResultsDoc.data();
        return {
          sprint: data.sprint?.isComplete
            ? 'Complete'
            : data.sprint
            ? 'In Progress'
            : 'Not Started',
          pace: data.pace?.isComplete
            ? 'Complete'
            : data.pace
            ? 'In Progress'
            : 'Not Started',
          marathon: data.marathon?.isComplete
            ? 'Complete'
            : data.marathon
            ? 'In Progress'
            : 'Not Started',
        };
      }
    } catch (error) {
      console.error('Error fetching today status:', error);
    }
    return {
      sprint: 'Not Started',
      pace: 'Not Started',
      marathon: 'Not Started',
    };
  };

  const fetchMonthData = async (userId, currentDate) => {
    const monthData = [];
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const totalDays = new Date(year, month + 1, 0).getDate();

    for (let day = 1; day <= totalDays; day++) {
      const date = new Date(year, month, day)
        .toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
        .replace(/\//g, '-');

      const userResultsDocRef = doc(db, 'users', userId, 'results', date);
      try {
        const userResultsDoc = await getDoc(userResultsDocRef);
        if (userResultsDoc.exists()) {
          const data = userResultsDoc.data();
          const setsCompleted = ['sprint', 'pace', 'marathon'].filter(
            (type) => data[type]?.isComplete
          ).length;
          monthData.push({ date, setsCompleted });
        } else {
          monthData.push({ date, setsCompleted: 0 });
        }
      } catch (error) {
        console.error('Error fetching day data:', error);
        monthData.push({ date, setsCompleted: 0 });
      }
    }
    return monthData;
  };

  const fetchStreak = async (userId) => {
    try {
      // Example logic to fetch the streak count from user's data
      // Update this logic based on how streak is stored in your database
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        return userData.streak || 0; // Assume streak is stored directly in user data
      }
    } catch (error) {
      console.error('Error fetching streak:', error);
    }
    return 0;
  };

  return (
    <CardContext.Provider
      value={{
        username,
        dateString,
        dayStreak,
        status,
        monthData,
        loading,
      }}
    >
      {children}
    </CardContext.Provider>
  );
};
